import React from 'react';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { colors } from 'styles/colors';

export const rowClassName = (_, index) => {
  return index % 2 === 0 ? 'row-odd' : 'row-even';
};

export const stageOptions = [
  {
    id: 1,
    value: false,
    label: 'Implantação',
  },
  {
    id: 2,
    value: true,
    label: 'Produção',
  },
];

export const tableStatusParser = {
  SUCESSO: {
    icon: (
      <CheckCircleOutlined style={{ width: '12px', color: colors.green6 }} />
    ),
    label: 'Sucesso',
    labelColor: colors.green6,
    bgColor: colors.green1,
    border: colors.green3,
    width: '84px',
  },
  ANDAMENTO: {
    icon: <SyncOutlined style={{ width: '12px', color: colors.primary6 }} />,
    label: 'Andamento',
    labelColor: colors.primary6,
    bgColor: colors.primary1,
    border: colors.primary3,
    width: '96px',
  },
  ERRO: {
    icon: (
      <ExclamationCircleOutlined
        style={{ width: '12px', color: colors.gray9 }}
      />
    ),
    label: 'Erro',
    labelColor: colors.gray9,
    bgColor: colors.gray2,
    border: colors.gray5,
    width: '56px',
  },
  ALERTA: {
    icon: (
      <ExclamationCircleOutlined
        style={{ width: '12px', color: colors.gold6 }}
      />
    ),
    label: 'Alerta',
    labelColor: colors.gold6,
    bgColor: colors.gold1,
    border: colors.gold3,
    width: '66px',
  },
  PENDENTE: {
    icon: (
      <ClockCircleOutlined style={{ width: '12px', color: colors.gray7 }} />
    ),
    label: 'Pendente',
    labelColor: colors.gray7,
    bgColor: colors.gray2,
    border: colors.gray5,
    width: '102px',
  },
};

export const modalStepsParser = {
  SUCESSO: {
    icon: (
      <CheckCircleFilled style={{ fontSize: '24px', color: colors.green6 }} />
    ),
    tailColor: colors.green6,
  },
  ANDAMENTO: {
    icon: <SyncOutlined style={{ fontSize: '24px', color: colors.primary6 }} />,
    tailColor: colors.primary6,
  },
  ERRO: {
    icon: (
      <ExclamationCircleOutlined
        style={{ fontSize: '24px', color: colors.gray9 }}
      />
    ),
    tailColor: colors.gray10,
  },
  ALERTA: {
    icon: (
      <ExclamationCircleOutlined
        style={{ fontSize: '24px', color: colors.gold6 }}
      />
    ),
    tailColor: colors.gold6,
  },
  PENDENTE: {
    icon: (
      <ClockCircleOutlined style={{ fontSize: '24px', color: colors.gray9 }} />
    ),
    tailColor: colors.gray7,
  },
};
